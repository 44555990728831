import React, { Suspense } from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import './index.css';
import App from './App';
import { ApolloProvider } from '@apollo/client';
import { ThemeModeContextProvider } from 'contexts/useThemeModeContext';
import ServiceProvider from 'services/ServiceProvider';

const container = document.getElementById("root");

ServiceProvider.init();

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <ApolloProvider client={ServiceProvider.getApolloClient()}>
        <Router>
          <ThemeModeContextProvider>
            <Suspense fallback="loading">
              <App />
            </Suspense>
          </ThemeModeContextProvider>
        </Router>
      </ApolloProvider>
    </React.StrictMode>
  );
}
