import { ApolloClient } from "@apollo/client";
import jpex from "jpex";
import LocalStorageOrQueryTokenRepository from "repositories/LocalStorageOrQueryTokenRepository";
import { ApolloClientProvider } from "services/ApolloClientProvider";
import LocalAuthenticationService from "services/LocalAuthenticationService";

import StorageUserPreferenceService from "services/LocalUserPreferenceService";
import { AuthenticationService } from "typings/AuthenticationService";
import { UserPreferenceService } from "typings/UserPreferenceService";


function getUserPreferenceService() {
  return new StorageUserPreferenceService(window.localStorage);
}

function getTokenRepository() {
  return LocalStorageOrQueryTokenRepository();
}

let apolloClient: ApolloClient<any>;

function getApolloClient() {
  if (!apolloClient) {
    apolloClient = new ApolloClientProvider(
      getTokenRepository(),
    ).getApolloClient();
  }

  return apolloClient;
}

function getAuthenticationService() {
  return new LocalAuthenticationService(
    getTokenRepository()
  );
}

jpex.factory<UserPreferenceService>("UserPreferenceService", [], () =>
  getUserPreferenceService()
);

jpex.factory<AuthenticationService>("AuthenticationService", [], () =>
  getAuthenticationService()  
);

function init() {}

const ServiceProvider = {
  init,
  getApolloClient
};

export default ServiceProvider;
