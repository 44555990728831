import jwt_decode from "jwt-decode";

import { TokenRepository } from "typings/TokenRepository";
import { Jwt } from "typings/Jwt";
import { AuthenticationService } from "typings/AuthenticationService";

function hasNotExpired(exp?: number) {
  if (!exp) return false;

  return exp > Date.now() / 1000;
}

export default class LocalAuthenticationService
  implements AuthenticationService
{
  constructor(
    private tokenRepository: TokenRepository,
  ) {}

  getToken() {
    return this.tokenRepository.retrieve();
  }

  isAuthenticated() {
    const token = this.getDecodedToken();

    return (hasNotExpired(token?.exp));
  }

  private getDecodedToken() {
    try {
      const token = this.getToken();
      return token ? this.decodeToken(token.value) : undefined;
    } catch {
      return undefined;
    }
  }

  private decodeToken(token: string): Jwt {
    return jwt_decode(token);
  }
}