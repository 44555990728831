import { OverPage } from "pages/Over";
import { Route } from "react-router-dom";

export function Routes() {
  return (
    <>
      <Route key="over" element={<OverPage />} path="/*" />
    </>
  );
}
